:root{
    --primary-font-size-MB: 16px;
    --big-font-size-MB: 20px;
    --bg-color: #000;
    --text-blue:#86e7f6;
    
}
*{
    margin: 0;
    padding: 0;
}
.ledMB-container{
    background-color: var(--bg-color);
    font-size: var(--primary-font-size-MB);
    color:white;
}
.ledMB-header{
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    height: 100px;
    align-items: center;
  
}
.ledMB-header div{
    display: flex;
   
}
.headerMB-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    color: var(--text-blue)

    

}
.text-blue{
    color: var(--text-blue)
}
.clock-mb{
    display: flex;
    justify-content: right;
    margin-right: 10px;
}
.page-name{
    font-weight: 600;
    font-size: var(--primary-font-size-MB);
    text-align: center;
}