.d-table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
  height: 100%;
}

.d-table tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

.d-table-bordered {
  border: 1px solid #ddd;
}

.d-table>tbody>tr {
  width: 100%;
  height: 30px;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.d-table>thead>tr {
  height: 36px;
}

.d-table>tbody>tr:hover {
  background-color: #f5f5f5;
}