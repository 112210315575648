.led-content {
  flex: 1;
  display: flex;
  background-color: #000;

}


.led-content-children:first-child {
  flex: 4;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
}

.led-content-children:nth-child(2) {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
}

.led-content-children:nth-child(2) div {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.led-content-grandchildren:first-child {
  flex: 2;
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: center; */
}

.led-content-grandchildren:nth-child(2) {
  flex: 1;
  display: flex;
  padding-left: 8px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.led-content-greatgrandchildren {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}