.tab-content {
  padding-bottom: 0px;
  background: #fff;
  margin-top: 0;
  margin-bottom: 0;
  /* margin-left: 1%;
  margin-right: 1%; */
  border-radius: 12px;
}
.tab-content > .ant-tabs > .ant-tabs-bar {
  margin-bottom: 0px;
  background: #fff;
}



.tab-content > .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane, .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  padding-left: 0px;
  padding-right: 0px;
}

.ant-tabs-tab-active {
  font-size: 14px;
  font-weight: bold !important;
  font-style: normal;
  letter-spacing: 0;
  color: #1890ff;
}

.ant-tabs-tab {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  color: #585858;
}
.wd-panel-h {
  border-bottom: 2px solid #2767b3;
}
.wd-panel-h {
  display: block;
  background: #fff;
  height: 32px;
  overflow: hidden;
  /* margin-bottom: 15px; */
}
.wd-panel-h .wd-panel-h-title {
  background: #2767b3;
}
.wd-panel-h .wd-panel-h-title {
  color: #fff;
  font-size: 16px;
  padding: 5px 13px 6px 10px;
  margin-left: -2px;
  display: block;
  float: left;
  z-index: 0;
}
