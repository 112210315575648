.d-container {
  margin-bottom: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "Times New Roman", Times, serif;
  border: 0.5px solid #fff;
  height: 120px;
  width: 90px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  /* background-color: rgb(255, 252, 248); */
}

.d-header {
  display: flex;
  flex-direction: column;
  background-color: #77777777;
  height: 26px;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.d-text {
  font-size: 11px;
  text-align: center;
  font-weight: 'bold';
  color: #fff;
}

.d-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}