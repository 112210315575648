

.led-container {
  height: 100vh;
  background-color: #000;
  display: flex;
  flex-direction: column;
  color: #fff;
}

.led-border-top {
  border-top: 1px solid #fff;
}

.led-border-left {
  border-left: 1px solid #fff;
}

.led-border-right {
  border-right: 1px solid #fff;
}

.led-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #000;
}

.led-header div {
  align-items: center;
  justify-content: center;
  padding: 16px 16px;
  /* flex: 1; */
  display: flex;
  flex-direction: row;
}

.led-header h3 {
  font-size: 32px;
  font-weight: 700;
  margin: 0;
}

.led-station-name {
  display: flex;
  flex-direction: row;
}

.led-station-name div:first-child {
  display: flex;
  flex: 4;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
}

.led-station-name div:nth-child(2) {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.big-bold-text {
  font-size: 19px;
  font-weight: 700;
  color: #fff;
  margin-left: 8px;
}

.normal-text {
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  margin-left: 8px;
}

.measuring-text {
  font-size: 19px;
  font-weight: 700;
  color: #fff;
  margin: 16px;
}
.led-footer {
  text-align: center;
  background-color: #000;

  /* padding: 16px; */
}

.led-footer h4 {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
}


.led-row {
  flex: 1;
}

.text-red {
  color: red;
}

.text-blue {
  color: #86e7f6;
}
