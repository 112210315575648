.wd-panel-h {
  border-bottom: 2px solid #2767b3;
}

.wd-panel-h {
  display: block;
  background: #fff;
  height: 32px;
  overflow: hidden;
  /* margin-bottom: 15px; */
}

.wd-panel-h .wd-panel-h-title {
  background: #2767b3;
}

.wd-panel-h .wd-panel-h-title {
  color: #fff;
  font-size: 16px;
  padding: 5px 13px 6px 10px;
  margin-left: -2px;
  display: block;
  float: left;
  z-index: 0;
}

#box-recommandation>div {
  margin: 0 !important;
}

.box-wqi {
  border: 5px solid #ffff99;
  border-radius: 15px;

}