.de-container {
  min-width: 370px;
  font-family: "Times New Roman", Times, serif;
  background-color: #ffffff;
  width: 100%;
  
}

.d-table {
  height: 142px;
  font-size: 10px;
}
