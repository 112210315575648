table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
    background-color: #000000;
  }
  td, th {
    border: 1px solid #ddd;
    padding: 10px;
  
  }

.table-aqi-hour{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 50px;
  font-weight: 600;
}
.table-aqi-hour p {
 margin: 0;
  width: 33%;
}
.table-aqi-hour img{
 
}